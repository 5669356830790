var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"ml-4",staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.cardTitle))])])],1)],1),_c('v-card-text',_vm._l((_vm.settings),function(setting){return _c('v-card',{key:setting.id,staticClass:"mx-auto mb-7",attrs:{"max-width":"650","outlined":"","elevation":"15"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 mb-6"},[_vm._v(" "+_vm._s(setting.id)+" ")]),_c('v-list-item-subtitle',_vm._l((_vm.languages),function(language){return _c('v-row',{key:language.lang},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(
                      setting.setting_content && setting.setting_content.name
                    )?_c('v-text-field',{attrs:{"label":_vm.$t('FORMS.name') + ' (' + language.lang + ')',"rules":_vm.nameRules},model:{value:(setting.setting_content.name[language.lang]),callback:function ($$v) {_vm.$set(setting.setting_content.name, language.lang, $$v)},expression:"setting.setting_content.name[language.lang]"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(
                      setting.setting_content &&
                      setting.setting_content.url_prefix
                    )?_c('v-text-field',{attrs:{"label":_vm.$t('FORM_INPUT_NAMES.url_prefix') +
                      ' (' +
                      language.lang +
                      ')',"rules":_vm.nameRules},model:{value:(
                      setting.setting_content.url_prefix[language.lang]
                    ),callback:function ($$v) {_vm.$set(setting.setting_content.url_prefix, language.lang, $$v)},expression:"\n                      setting.setting_content.url_prefix[language.lang]\n                    "}}):_vm._e()],1)],1)}),1)],1)],1),_c('v-card-actions',[(_vm.permissionCan('update'))?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":function($event){return _vm.handleSaveSetting(setting)}}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1)],1)}),1)],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }